import React, {useState} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {parseCookies, destroyCookie} from 'nookies';
import {Container, Col, Row, Dropdown, Nav} from 'react-bootstrap';
import {FaUser, FaUserAlt, FaUserPlus} from 'react-icons/fa';
import {FiLogOut} from '@react-icons/all-files/fi/FiLogOut';
import {navigate} from 'gatsby';
import cn from 'classnames';

import styles from './header.module.scss';
import logoSrc from '../../images/Logo.svg';

const MenuBurger = styled.button`
  position: fixed;
  padding: 0;
  top: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: white;
    border-radius: 5px;
    transform-origin: 1px;
    position: relative;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${({opened}) => (opened ? 'rotate(47deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({opened}) => (opened ? '0' : '1')};
    }
    :nth-child(3) {
      transform: ${({opened}) => (opened ? 'rotate(-47deg)' : 'rotate(0)')};
    }
  }
`;

const SUB_DOMAIN = 'profile';

// TODO: move to config
const Routes = {
  GATSBY_BECOME_BETA: `/beta`,
  LOGIN: `/${SUB_DOMAIN}/auth/login`,
  PROFILE: `/${SUB_DOMAIN}/profile/update`,
};

const Header = ({menuLinks, onToggle, location}) => {
  const [state, setState] = useState({menuOpened: false});

  const toggleMenu = (event) => {
    setState({menuOpened: !state.menuOpened});
    onToggle(event, !state.menuOpened);
  };

  const getEmail = () => {
    const cookies = parseCookies();
    return cookies.email;
  };

  const COOKIES_OPT = {path: '/'};

  const logout = () => {
    destroyCookie(null, 'email', COOKIES_OPT);
    destroyCookie(null, 'accessToken', COOKIES_OPT);
    destroyCookie(null, 'refreshToken', COOKIES_OPT);
    destroyCookie(null, 'avatarURL', COOKIES_OPT);
  };

  const onClickLogout = () => {
    logout();
    navigate(Routes.LOGIN);
  };

  const Links = ({menuLinks}) => {
    return (
      <Nav className={styles.header__menu} activeKey={location.pathname}>
        {menuLinks.map((link) => (
          <Nav.Item key={link.link}>
            <Nav.Link className={styles.header__menu_link} href={link.link}>
              {link.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    );
  };

  const NotAuthorizedLinks = () => {
    return (
      <Col className={cn(styles.header__auth)}>
        <Link
          className={cn(styles.header__auth_link, 'd-flex align-items-center', {
            [styles.header__auth_link_active]: location.pathname.includes(
              'beta',
            ),
          })}
          to={Routes.GATSBY_BECOME_BETA}
          key={'Become-link'}
        >
          <FaUserPlus fontSize="1.3em"/> Become a member
        </Link>

        <Link
          className={cn(styles.header__auth_link, 'd-flex align-items-center')}
          to={Routes.LOGIN}
          key={'Login-link'}
        >
          <FaUserAlt /> Login
        </Link>
      </Col>
    );
  };

  const AuthorizedMenuLinks = ({email}) => {
    return (
      <Dropdown className={cn(styles.header__profile)}>
        <Dropdown.Toggle
          className={cn(
            styles.header__profile_dropdown,
            'd-flex flex-row align-items-center p-0 g-0',
          )}
          variant="link"
        >
          <div
            className={cn(
              styles.header__profile_dropdown_avatar,
              'd-flex flex-column align-items-center justify-content-center',
            )}
          >
            <FaUserAlt />
          </div>
          {email}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={cn(styles.header__profile_menu, 'd-flex flex-column g-0')}
        >
          <Dropdown.Item
            className={cn(styles.header__profile_menu_item)}
            href={Routes.PROFILE}
          >
            <FaUser />
            Profile
          </Dropdown.Item>

          <Dropdown.Item
            className={styles.header__profile_menu_item}
            onClick={onClickLogout}
          >
            <FiLogOut />
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      {/* Desktop header */}
      <Container fluid className={cn(styles.header, 'd-none d-lg-flex')}>
        <Col className={styles.header__menulinks}>
          <Links menuLinks={menuLinks} />
        </Col>
        <Col className="col-auto d-flex flex-column align-content-center justify-content-center">
          {!getEmail() && <NotAuthorizedLinks />}
          {getEmail() && <AuthorizedMenuLinks email={getEmail()} />}
        </Col>
      </Container>

      {/* Mobile header */}
      <Container
        fluid
        className={cn(
          styles.header__mobile,
          {[styles.opened]: state.menuOpened},
          'd-flex flex-column d-lg-none navbar-fixed-top g-0',
        )}
      >
        <div
          className={cn(styles.header__mobile_top, 'd-flex flex-row w-100 g-0')}
        >
          <Col
            className={cn(styles.header__mobile_top_logo, {
              'd-none': state.menuOpened,
            })}
          >
            {location.pathname !== '/' ? (
              <Link to="/" className={styles.header__mobile__logo}>
                <img src={logoSrc} alt="logo" />
              </Link>
            ) : null}
          </Col>

          <Col
            className={cn(styles.header__mobile_top_auth, {
              'd-none': !state.menuOpened,
            })}
          >
            <Col
              className={cn(
                styles.header__mobile_top_auth_login,
                'd-flex flex-column justify-content-center align-items-start col-auto g-0',
              )}
            >
              {!getEmail() && (
                <Link to={Routes.LOGIN}>
                  <>
                    <FaUserAlt /> Login
                  </>
                </Link>
              )}

              {getEmail() && (
                <Link to={Routes.PROFILE}>
                  <div className={styles.header__mobile_top_auth_email}>
                    <div
                      className={cn(
                        styles.header__mobile_top_auth_email_avatar,
                        'd-flex flex-column align-items-center justify-content-center g-0 p-0',
                      )}
                    >
                      <FaUserAlt />
                    </div>
                    {getEmail()}
                  </div>
                </Link>
              )}
            </Col>
          </Col>

          <Col
            className={cn(
              styles.header__mobile_top_burger,
              'col-auto ms-auto g-0 p-0 m-0',
            )}
          >
            <MenuBurger opened={state.menuOpened} onClick={toggleMenu}>
              <div />
              <div />
              <div />
            </MenuBurger>
          </Col>
        </div>

        <Container
          fluid
          className={cn(
            styles.header__mobile_content,
            {
              ['d-flex h-100']: state.menuOpened,
            },
            'd-none flex-column justify-content-between overflow-auto g-0 w-100',
          )}
        >
          <Row
            className={cn(
              'd-flex flex-column flex-grow-1 align-items-center g-0',
            )}
          >
            <Links menuLinks={menuLinks} />
          </Row>

          <Row
            className={cn(
              styles.header__mobile_content_logout,
              'flex-grow-1 g-0',
            )}
          >
            <Col
              className={cn(
                'd-flex flex-row align-items-center justify-content-center m-3',
              )}
            >
              {getEmail() && <a onClick={onClickLogout} href="">Logout</a>}

              {!getEmail() && (
                <Link to={Routes.GATSBY_BECOME_BETA}>
                  <>
                    <FaUserPlus /> Become a member
                  </>
                </Link>
              )}
            </Col>
          </Row>

          <Row
            className={cn(
              styles.header__mobile_content_footer,
              'd-flex flex-column align-content-center g-0',
            )}
          >
            <Row className={styles.header__mobile_content_footer_soc}>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.instagram.com/cellrsociety/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </Row>
            <Link
              to="/privacy-notice/"
              className={styles.header__mobile_content_footer_item}
              id="menu_privacy"
            >
              Privacy Notice
            </Link>
            <p className={styles.header__mobile_content_footer_item}>
              Copyright © 2021 Cellar Ventures, Inc. <br /> All rights reserved.
            </p>
          </Row>
        </Container>
      </Container>
    </>

    // <>
    //   <header className={styles.header__mobile}>
    //     {location.pathname !== '/' ? (
    //       <Link to="/" className={styles.header__mobile__logo}>
    //         <img src={logoSrc} alt="logo" />
    //       </Link>
    //     ) : null}

    //     <MenuBurger opened={state.menuOpened} onClick={toggleMenu}>
    //       <div />
    //       <div />
    //       <div />
    //     </MenuBurger>
    //     {state.menuOpened ? (
    //       <MenuLinks opened={state.menuOpened}>
    //         <div className={styles.header__mobile_auth}>
    //           <div className={styles.header__mobile_auth_login}>
    //             {!getEmail() && (
    //               <Link to={Routes.LOGIN} key="login-link">
    //                 <FaUserAlt /> Login
    //               </Link>
    //             )}
    //           </div>

    //           {getEmail() && (
    //             <Link to={Routes.PROFILE} key="profile-link">
    //               <div className={styles.header__mobile_auth_email}>
    //                 <div className={styles.header__mobile_auth_email_avatar}>
    //                   <FaUserAlt />
    //                 </div>
    //                 {getEmail()}
    //               </div>
    //             </Link>
    //           )}
    //         </div>
    //         {menuLinks.map((link) => (
    //           <Link
    //             to={link.link}
    //             key={link.name}
    //             activeClassName={styles.header__mobile_active}
    //           >
    //             {link.name}
    //           </Link>
    //         ))}

    //         {/* Logout link */}
    //         <div className={styles.header__mobile_logout}>
    //           {getEmail() && (
    //             <Link>
    //               <div
    //                 className={styles.header__mobile_logout_link}
    //                 onClick={onClickLogout}
    //               >
    //                 <FaUser /> Logout
    //               </div>
    //             </Link>
    //           )}

    //           {!getEmail() && (
    //             <a href={Routes.GATSBY_BECOME_BETA}>
    //               <>
    //                 <FaUserPlus /> Become a member
    //               </>
    //             </a>
    //           )}
    //         </div>

    //       </MenuLinks>
    //     ) : null}
    //   </header>
    //   <header className={styles.header}>
    //     {menuLinks.map((link) => (
    //       <Link
    //         to={link.link}
    //         key={link.name}
    //         activeClassName={styles.header_active}
    //       >
    //         {link.name}
    //       </Link>
    //     ))}
    //     {!getEmail() && <NotAuthorizedLinks />}
    //     {getEmail() && <AuthorizedMenuLinks email={getEmail()} />}
    //   </header>
    // </>
  );
};

export default Header;
