import React from "react";
import styles from "./blog.module.scss";
import Layout from "../../components/layout/layout";
import { Link } from "gatsby";
import {Col, Row} from "react-bootstrap";
import img from '../../images/Varietal.jpeg';



const Blog = ({ location }) => {

  return (
    <Layout location={location}>
        <div className={styles.content}>
        <h1>Blog <span style={{color: 'red'}}>(is in development!)</span></h1>
      <div className={styles.contacts}>
        <Row className={styles.contacts__container}>
        <Col xs={3}>
        <img src={img} alt="blog image" className={styles.blogImage}/>
        </Col>
          <Col xs={8} className={styles.contacts__info}>
            <h3>Header</h3>
            <p>
              Whether users want to inventory the holdings of their cellar, keep track of their
              wish list when they travel and dine, trade with other cellar owners, or just receive
              the occasional special that is aligned with their tastes, CELLR will be that destination…
            </p>
            <p>
              CELLR is currently available as an invite only app on Apple Test Flight. We are
              constantly adding new Users and would love to have you as a part of the CELLR community!
              To get added to our Beta group send us an message with your info or visit our
              <Link to="/beta/">Become Beta User</Link> page.
            </p>
            <form className={styles.contacts__form}>
                <button type="submit">read</button>
              </form>
          </Col>
        </Row>
      </div>
      <div className={styles.contacts}>
        <Row className={styles.contacts__container}>
        <Col xs={3}>
        <img src={img} className={styles.blogImage}/>
        </Col>
          <Col xs={8} className={styles.contacts__info}>
          <h3>Header</h3>
            <p>
              Whether users want to inventory the holdings of their cellar, keep track of their
              wish list when they travel and dine, trade with other cellar owners, or just receive
              the occasional special that is aligned with their tastes, CELLR will be that destination…
            </p>
            <form className={styles.contacts__form}>
                <button type="submit">read</button>
              </form>
          </Col>
        </Row>
      </div>
      <div className={styles.contacts}>
        <Row className={styles.contacts__container}>
        <Col xs={3}>
        <img src={img} className={styles.blogImage}/>
        </Col>
          <Col xs={8} className={styles.contacts__info}>
          <h3>Header</h3>
            <p>
              Whether users want to inventory the holdings of their cellar, keep track of their
              wish list when they travel and dine, trade with other cellar owners, or just receive
              the occasional special that is aligned with their tastes, CELLR will be that destination…
            </p>
            <form className={styles.contacts__form}>
                <button type="submit">read</button>
              </form>
          </Col>
        </Row>
      </div>
      <div className={styles.contacts}>
        <Row className={styles.contacts__container}>
        <Col xs={3}>
        <img src={img} className={styles.blogImage}/>
        </Col>
          <Col xs={8} className={styles.contacts__info}>
          <h3>Header</h3>
            <p>
              Whether users want to inventory the holdings of their cellar, keep track of their
              wish list when they travel and dine, trade with other cellar owners, or just receive
              the occasional special that is aligned with their tastes, CELLR will be that destination…
            </p>
            <form className={styles.contacts__form}>
                <button type="submit">read</button>
              </form>
          </Col>
        </Row>
      </div>
      </div>
    </Layout>
  )
}

export default Blog;
