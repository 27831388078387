import React from 'react';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import styles from './sidebar.module.scss';
import logoSrc from '../../images/logo.svg';
import Stepper from '../stepper/stepper';
import Image from '../image';

const Sidebar = ({location, menuLinks}) => {
  const [url] = menuLinks.filter((link) => link.link === location.pathname);
  const defaultImg = 'black.png';
  let imgIE = "../backgrounds/" + (url ? url.sidebarUrl: defaultImg);
  let imgNotIE = Image(url ? url.sidebarUrl : defaultImg);


  // Fix background for Become beta page
  if (location.pathname.includes('beta')) {
    imgIE = '../backgrounds/background-7-1.png';
    imgNotIE = Image('background-7-1.png');
  }  

  return (
    <div className={styles.sidebar}
         id="sidebar"
         style={{
           background:`url(${imgIE})`,
           backgroundSize: "cover",
         }}>
      <div className={styles.sidebar__image}>
        <Img fluid={imgNotIE} className={styles.sidebar__image} />
      </div>
      <div className={styles.sidebar__container}>
        {!location.pathname.includes('privacy-notice') && !location.pathname.includes('beta') && (
          <Stepper
            location={location}
            menuLinks={menuLinks} />
        )}
      </div>
      {location.pathname !== '/' && (
        <Link to="/" className={styles.sidebar__logo}>
          <img src={logoSrc} alt="logo" />
        </Link>
      )}
    </div>
  )
};

export default Sidebar;
